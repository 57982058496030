// Generated by Framer (b742ddc)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {ey_Hyn0Qe: {hover: true}};

const cycleOrder = ["ey_Hyn0Qe"];

const serializationHash = "framer-W4m4N"

const variantClassNames = {ey_Hyn0Qe: "framer-v-53vabv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, fM4JZBIBG: icon ?? props.fM4JZBIBG ?? {src: "https://framerusercontent.com/images/2a5UuBZGLeppqAHRILXIkpa0Bg.svg"}, MEUiQFtHg: link ?? props.MEUiQFtHg} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MEUiQFtHg, fM4JZBIBG, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ey_Hyn0Qe", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={MEUiQFtHg}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-53vabv", className, classNames)} framer-dt95nn`} data-framer-name={"Social Button"} layoutDependency={layoutDependency} layoutId={"ey_Hyn0Qe"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-a4a47d71-3fee-4027-9af4-e581c21ef1a0, rgb(38, 38, 38))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{"ey_Hyn0Qe-hover": {backgroundColor: "var(--token-da0e270c-ec4d-4e51-a2f0-7f345a7facc5, rgb(51, 51, 51))"}}} {...addPropertyOverrides({"ey_Hyn0Qe-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (10 + ((((componentViewport?.height || 44) - 20) - 24) / 2)))), pixelHeight: 24, pixelWidth: 24, sizes: "24px", ...toResponsiveImage(fM4JZBIBG), ...{ positionX: "center", positionY: "center" }}} className={"framer-1t9ee5v"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"RCjusqmsx"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-W4m4N.framer-dt95nn, .framer-W4m4N .framer-dt95nn { display: block; }", ".framer-W4m4N.framer-53vabv { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px; position: relative; text-decoration: none; width: min-content; }", ".framer-W4m4N .framer-1t9ee5v { flex: none; height: 24px; overflow: visible; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-W4m4N.framer-53vabv { gap: 0px; } .framer-W4m4N.framer-53vabv > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-W4m4N.framer-53vabv > :first-child { margin-left: 0px; } .framer-W4m4N.framer-53vabv > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"onuABzEhH":{"layout":["auto","auto"]}}}
 * @framerVariables {"MEUiQFtHg":"link","fM4JZBIBG":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerna_fflQGR: React.ComponentType<Props> = withCSS(Component, css, "framer-W4m4N") as typeof Component;
export default Framerna_fflQGR;

Framerna_fflQGR.displayName = "Buttons/ Social Button";

Framerna_fflQGR.defaultProps = {height: 44, width: 44};

addPropertyControls(Framerna_fflQGR, {MEUiQFtHg: {title: "Link", type: ControlType.Link}, fM4JZBIBG: {__defaultAssetReference: "data:framer/asset-reference,2a5UuBZGLeppqAHRILXIkpa0Bg.svg?originalFilename=email-svgrepo-com+%281%29+1+%288%29.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerna_fflQGR, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})